/* You can add global styles to this file, and also import other style files */
@import "~bootstrap-icons/font/bootstrap-icons.css";
.dropzone { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
    /* min-width: 80vw; */
    font-weight: 200;
    height: 300px;
    border: 2px dashed #f16624;
    border-radius: 5px;
    background: white;
    margin: 10px 0;
  }
  
  .modal-content{
    border:none !important
  }
  .dropzone .hovering {
        border: 2px solid #f16624;
        color: #dadada !important;
    }
  
  .dropzone .file-label {
        font-size: 1.2em;
    }
    
  progress::-webkit-progress-value {
    transition: width 0.1s ease;
  }
  
  img {
    width: 250px
  }
  
  .h-100vh {
    height: 100vh;
  }
  
  hr {
    background-color: initial;
  }
  

  .nav-pills .nav-link  {
    color : var(--text-secondary);
  }
  .nav-pills .nav-link:hover  {
    color : var(--bs-gray-dark);
  }

  .nav-pills .nav-link.active
  {
    background-color : var(--bs-gray-dark) !important;
    color: var(--text-white);
  }

  .b-menu {
    padding:5px;
    border-top:1px solid grey;
    background-color: white;
  }

  .dailog-transparent-css {
    background-color: transparent !important;
    backdrop-filter: blur(2px);
  }

  .dailog-transparent-css .mat-dialog-container {
    background-color: transparent !important;
    backdrop-filter: blur(2px);
    padding: 3px !important;
  }



  .dailog-transparent-css .mat-mdc-dialog-container .mdc-dialog__surface{
    background: #666666 !important;
  }
  
  .dailog-transparent-css-loading .mat-mdc-dialog-container .mdc-dialog__surface{
    background-color: transparent !important;
    backdrop-filter: blur(2px);
  }


  .dailog-padding-css > mat-dialog-container {
    padding: 3px !important;
  }

  /* 
    common classes
  */

  .brand-font-size {
    font-size: 12.5px !important;
  } 
  .dailog-userPopUp >  mat-dialog-container{
    color: white;
    background: rgba(0, 0, 0, .0001) !important;
    padding: 0px;
    margin: 0px;
  }

  .modal-backdrop.show{
      opacity: 0 !important;
  }

  .model-garmentPopup .modal-content{
    /* background-color: transparent !important; */
    color: white;
    -webkit-backdrop-filter: blur(6px);
      backdrop-filter: blur(6px);
     background: rgba(0 0 0 / 12%)

  }

  .model-garmentPopupClass .modal-content{
    margin-left: 20px;
    width: 288px !important;
    background-color: transparent !important;
  }

  .model-userPopup .modal-content{
    background-color: transparent !important;
  }
  .pt-4px {
    padding-top:4px;
  }


  .model-login-popup .modal-content{
     color: white;
    -webkit-backdrop-filter: blur(6px);
      backdrop-filter: blur(6px);
     background: rgba(0 0 0 / 12%)
  }




  .model-login-popup{
    position: fixed !important;
    top: auto !important;
    right: auto !important;
    left: auto !important;
    bottom: 48px !important;
    width: 95% !important;
 }

/* 
 .model-login-popup {
  position: fixed !important;
  right: 49px !important;
  left: auto !important;
  top: 48px !important;
  width: 95% !important;
} */



@media (min-width:700px)  { 
  .model-login-popup{
      position: fixed !important;
      top: auto !important;
      right: auto !important;
      left: 95px !important;
      bottom: 38px !important;
      width: 95% !important;
  
  }
} 

   @media (min-width:1080px)  { 
    .model-login-popup{
      position: fixed !important;
      top: auto !important;
      right: 48px !important;
      left: auto !important;
      top: 48px !important;
      width: 95% !important;
    }
  } 


  /* @media (min-width:280px)  { 
    .model-login-popup{
      position: fixed !important;
      top: auto !important;
      right: auto !important;
      left: auto !important;
      bottom: 48px !important;
      width: 95% !important;
    }
   }
  @media (min-width:320px){ 
    .model-login-popup{
      position: fixed !important;
      top: auto !important;
      right: auto !important;
      left: auto !important;
      bottom: 48px !important;
      width: 95% !important;  }
   }

   @media (min-width:360px) and (min-height: 660px)  { 
    .model-login-popup{
      position: fixed !important;
      top: auto !important;
      right: auto !important;
      left: auto !important;
      bottom: 48px !important;
      width: 95% !important; }
   }
   @media (min-width:360px) { 
    .model-login-popup{
      position: fixed !important;
      top: auto !important;
      right: auto !important;
      left: auto !important;
      bottom: 48px !important;
      width: 95% !important;  }
   }
   @media (min-width:375px)  { 
    .model-login-popup{
      position: fixed !important;
      top: auto !important;
      right: auto !important;
      left: auto !important;
      bottom: 48px !important;
      width: 95% !important;
   }


   }
   @media (max-width:400px) and (min-height: 880px) { 
  .model-login-popup{
    position: fixed !important;
    top: auto !important;
    right: auto !important;
    left: auto !important;
    bottom: 48px !important;
    width: 95% !important;
  }
}
@media (min-width:641px)  {
   .model-login-popup{
    position: fixed !important;
    top: auto !important;
    right: auto !important;
    left: auto !important;
    bottom: 48px !important;
    width: 95% !important;
  }
  
  }

  @media (max-width: 375px) and (min-height: 660px) {
    .model-login-popup{
      position: fixed !important;
      top: auto !important;
      right: auto !important;
      left: auto !important;
      bottom: 48px !important;
      width: 95% !important;
  }
  }


.toast {
  background-color: black;
}

 */
